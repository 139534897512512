import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Richard = () => {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: {eq: "Richard.png"}) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`)

  return (
    <div class="card">
        <div class="card-image">
            <Img style={{maxHeight: "300px"}} className="image is-4by3" fluid={data.file.childImageSharp.fluid} />
        </div>
        <div class="card-content">
            <div class="content">
                <p class="title is-4">Richard Sparks</p>
            </div>
            <div class="content">
                <ul class="benefits-list is-marginless" type="">
                    <li>NASM CPT and Sports Performance Coach</li>
                    <li>General health and contest prep coach</li>
                    <li>5 time NPC class winner</li>
                    <li>2 time NPC overall champion</li>
                    <li>2019 NPC Men's Physique Mister Kentucky</li>
                    <li>Owner Body Solutionz of St. Matthews Personal Training Gym</li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Richard





