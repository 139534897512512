import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Kaitlyn = () => {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: {eq: "Kaitlyn.png"}) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`)

  return (
    <div class="card">
        <div class="card-image">
            <Img style={{maxHeight: "300px"}} className="image is-4by3" fluid={data.file.childImageSharp.fluid} />
        </div>
        <div class="card-content">
            <div class="content">
                <p class="title is-4">Kaitlyn Brown</p>
            </div>
            <div class="content">
                <ul class="benefits-list is-marginless" type="">
                    <li>Doctor of Physical Therapy</li>
                    <li>Integrative Health Practitioner</li>
                    <li>Fitness Nutrition Specialist</li>
                    <li>Fitness Competitor</li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Kaitlyn





