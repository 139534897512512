import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => {
  const data = useStaticQuery(graphql`
  query {
      file(relativePath: {eq: "bssm-2.JPG"}) {
          childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
          }
      }   
  }
  `)

  return <Img 
            style={{
                position:'absolute', 
                backgroundPosition: 'center',
                height: '100%'
            }} 
            className="hero-background is-transparent" 
            fluid={data.file.childImageSharp.fluid} 
        />
}

export default Image
