import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Venmo = () => (
    <section class="hero is-primary is-bold">
      <div class="hero-body">
          <div class="container">
            <h1 class="title has-text-centered">
                Pre-register online and only pay $<span class="has-text-weight-bold" style={{textDecoration: "underline"}}>49.99</span>!
            </h1>
            <h2 class="subtitle has-text-centered">
                Please email any questions to <a href="mailto:bodysolutionzsm@gmail.com"><span>bodysolutionzsm@gmail.com</span></a>
            </h2>
            <div class="has-text-centered">
              <AnchorLink to="/#register" title="Pre-register Now">
                <button 
                  id="anchor-link" 
                  className="button is-large" 
                  type="anchor-link"
                  >
                    Register Now
                </button>
              </AnchorLink>
            </div>
        </div>
      </div>
    </section>
)

export default Venmo
