import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import Venmo from "../components/venmocta"
import Speakers from "../components/speakers"

const IndexPage = () => (
  <Layout>
    <SEO title="Advanced Supplementation and Gut Health Seminar hosted by HomeFit" />
    <Hero />
    <Speakers />
    <Venmo />
  </Layout>
)

export default IndexPage
