import React from "react"
import Richard from "../components/richard"
import Kaitlyn from "../components/kaitlyn"


const Speakers = () => (
    <section class="hero is-medium is-transparent is-bold has-background">
      <div class="hero-body">
        <div class="container">
            <div class="columns is-centered">
                <div class="column is-two-thirds-desktop is-full-tablet is-full-mobile">
                    <h1 style={{marginBottom: '.75rem', maxWidth: "770px"}} class="title is-size-2-desktop">
                        Join Richard Sparks and Kaitlyn Brown for an information-packed seminar!
                    </h1>
                </div>
            </div>
            <div class="columns is-centered">
                <div class="column is-one-third-desktop is-one-half-tablet is-full-mobile">
                    <Richard />
                </div>
                <div class="column is-one-third-desktop is-one-half-tablet is-full-mobile">
                    <Kaitlyn />
                </div>
            </div>
        </div>
      </div>
    </section>
)

export default Speakers