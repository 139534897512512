import React from "react"
import Image from "./image"
import Form from "../components/form"
import "../components/hero.scss"

const Hero = () => (
    <section class="hero is-medium is-transparent is-bold has-background">
      <Image />
      <div class="hero-body">
          <div class="container">
                <div class="columns">
                    <div class="column">
                        <div class="columns">
                        <div class="column">
                            <h1 style={{marginBottom: '.75rem'}} class="title is-size-2-desktop">
                                Learn about the importance of Gut Health and Advanced Supplementation!
                            </h1>
                            <span class="subtitle is-size-5 has-text-black" style={{marginBottom: "1.75rem"}}>
                                Richard Sparks, 2019 NPC Men's Physique Mister Kentucky, is teaming up with Kaitlyn Brown, a Doctor of Physical Therapy and Integrative Health Practitioner, to bring you a collaborative seminar covering <span class="has-text-weight-semibold">Advanced Supplementation</span> and <span class="has-text-weight-semibold">Gut Health</span>.
                            </span>
                        </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <div style={{marginBottom: ".75rem"}}>
                                    <span class="is-size-5 has-text-weight-bold">Advanced Supplementation:</span>
                                </div>
                                <div style={{paddingLeft: "0px", paddingBottom: "0px", paddingTop: "0px"}} class="column">
                                    <ul class="benefits-list" type="">
                                        <li>Supplementation for Healthy Individuals</li>
                                        <li>Dosage and Supplement Timing for Males and Females</li>
                                        <li>Weight loss, muscle building, heart health, joint health, nervous system support and a whole lot more</li>
                                        <li>Are there short cuts?</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="column">
                                <div style={{marginBottom: ".75rem"}}>
                                    <span class="is-size-5 has-text-weight-bold">Gut Health:</span>
                                </div>
                                <div style={{paddingBottom: "0px", paddingTop: "0px"}} class="column">
                                    <ul class="benefits-list" type="">
                                        <li>Importance of gut health/proper digestion</li>
                                        <li>Understanding the GI tract and digestive process</li>
                                        <li>Functional medicine GI lab testing</li>
                                        <li>Tips on maintaining a healthy gut</li>
                                        <li>Role of stress on the gut</li>
                                        <li>Functional medicine/naturopathic approaches to gut imbalances</li>
                                        <li>Digestive system imbalances (Acid reflux/GERD, Leaky gut/Intestinal permeability, SIBO, Candida overgrowth, H. Pylori)</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-one-third-desktop">
                        <div class="card register" id="register">
                        <div class="card-content">
                            <div class="content has-text-black">
                                <p class="title is-4">Pre-register for <span style={{color: "darkred", fontWeight: "700"}}>38% OFF</span>!</p>
                                <p class="title is-6">Free food and drinks will be provided. Contact us at <a href="mailto:bodysolutionzsm@gmail.com" alt="Body Solutionz Email Address">bodysolutionzsm@gmail.com</a> with any questions.</p>
                                <p class="is-marginless"><b>Pre-registration:</b> <span style={{color: "green", fontWeight: "700", textDecoration: "underline"}}>$49.99</span></p>
                                <p class="is-marginless"><b>At the door:</b> <span style={{color: "darkred", fontWeight: "700", textDecoration: "line-through"}}>$79.99</span></p>
                                <p class="is-marginless"><b>When:</b> 12:00 PM - 28 Mar 2020</p>
                                <p class=""><b>Where:</b> 129 St. Matthews Ave. Louisville, KY 40207</p>
                                <Form />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default Hero
